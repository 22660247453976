import { Controller } from "stimulus";

export default class extends Controller {

  // --------------------------------------------------------
  // INPUT -> SEARCH
  // --------------------------------------------------------
  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let form = document.querySelector('form#search');
      Rails.fire(form, 'submit');
      // form.requestSubmit();
    }, 200);
  }

  // --------------------------------------------------------
  // KEYUP -> KEYWORD SEARCH
  // --------------------------------------------------------
  keyword_search(event) {
    let keyword;
    const thisInput = $.trim($('input#search_keyword').val());
    // console.log(event.key);
    // console.log(thisInput);
    if (event.key == 'Enter' && keyword != thisInput){
      keyword = thisInput;
      this.search();
      $('input#search_keyword').css('color', 'black');
    } else if (keyword == thisInput) {
      $('input#search_keyword').css('color', 'black');
    } else {
      $('input#search_keyword').css('color', 'gray');
      if (thisInput.length == 0) {
        keyword = '';
        this.search();
      }
    }
  }

  // --------------------------------------------------------
  // CLEAR
  // --------------------------------------------------------
  clear() {
    $('table.status input[type=checkbox]').prop("checked", false);
    this.search();
  }
}
